import mixpanel from 'mixpanel-browser';

import api from './config/api';
import brand from '../core/config/brand';

import { getClientInfo, generateDeviceID } from './clientInfoService';
import { osName } from 'react-device-detect';
import { FullStory as FS } from '@fullstory/browser';

mixpanel.init(api.mixpanel.token, { ip: true });
const version = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0';
// const FS_EVENT_PREFIX = 'Photos';

export const publishPeoplePropsPreAuth = async () => {
  const uniqueId = localStorage.getItem('uniqueId')
    ? localStorage.getItem('uniqueId')
    : await generateDeviceID();
  mixpanel.identify(uniqueId);

  const userProperties = {
    UniqueId: uniqueId,
    carrierId: brand.carrierId,
    AppName: brand.appName,
    AppVersion: version,
    browserName: getClientInfo().browserName,
    browserVersion: getClientInfo().browserVersion,
    OsVersion: getClientInfo().osVersion,
    AppVersionCode: '1.0',
    FormFactor: getClientInfo().device,
    OmniChannel: 'Web',
    DeviceVendor: getClientInfo().deviceVendor || osName,
    DeviceModel: getClientInfo().deviceModel || getClientInfo().device,
    DeviceLocale: getClientInfo().locale,
  };
  mixpanel.people.set(userProperties);

  if (process.env.REACT_APP_ENABLE_FULLSTORY_EVENTS) {
    console.log(`updating FS user profile`);
    // const fsUserProperties = getFSUserProperties({
    //   ...userProperties,
    // });
    //console.log('FsUserProperties else', fsUserProperties);
    FS('setProperties', { type: 'user', properties: { ...userProperties } });
  }
};

// const getFSUserProperties = userProperties => {
//   return Object.keys(userProperties).reduce((prevProperties, currentPropertyKey) => {
//     let currentProperty = {
//       [`${FS_EVENT_PREFIX}_${currentPropertyKey}`]: userProperties[currentPropertyKey],
//     };

//     return { ...prevProperties, ...currentProperty };
//   }, {});
// };
