import React, { useCallback } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { publishAnonEvent } from '../../services/reportingService';
import { Button, Link } from '@soluto-private/mx-asurion-ui-react';
import { generateDeviceID } from '../../services/clientInfoService';

import heroImg from '../assets/Product-shots-mockup-2x.png';

const Hero = ({ classes }) => {
  const handleUserEvent = useCallback(event => {
    publishAnonEvent(event);
  }, []);
  const scrollTo = id => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };
  const handleLogin = useCallback(() => {
    const uniqueId =
      localStorage && localStorage.getItem('uniqueId')
        ? localStorage.getItem('uniqueId')
        : generateDeviceID();
    let path = `${process.env.REACT_APP_REDIRECT_URL}&u=${uniqueId}`;
    const event = {
      eventType: 'LinkClicked',
      link: 'SignIn',
      screen: 'MarketingLandingPage',
    };
    handleUserEvent(event);
    window.location.assign(path);
  }, [handleUserEvent]);
  const handleDownload = useCallback(() => {
    const event = {
      eventType: 'ButtonPressed',
      button: 'DownloadApp',
      screen: 'MarketingLandingPage',
    };
    handleUserEvent(event);
    scrollTo('download');
  }, [handleUserEvent]);
  const handleDownloadMobile = useCallback(() => {
    publishAnonEvent({
      eventType: 'ButtonPressed',
      button: 'DownloadApp',
      screen: 'MarketingLandingPage',
    });
    window.open(process.env.REACT_APP_DOWNLOAD_MOBILE_REDIRECT_LINK, '_blank').focus();
  }, []);

  return (
    <Paper className={classes.root} elevation={0} square>
      <Grid container>
        <div item md={6} className={classes.container}>
          <div item md={6} className={classes.left}>
            <div className={classes.title}>
              Unlimited photo storage included with your plan
            </div>
            <div className={classes.desc}>
              Your full resolution backup is available anytime, in the app and on the web.
            </div>
            <div className={classes.downloadBtnContainer}>
              <Button
                color="primary"
                size="large"
                variant="default"
                onClick={handleDownload}
              >
                Download the app
              </Button>
            </div>
            <div className={classes.smDownloadBtnContainer}>
              <Button
                color="primary"
                size="medium"
                variant="default"
                className={classes.smDownloadBtn}
                onClick={handleDownloadMobile}
              >
                Download the app
              </Button>
            </div>
            <div className={classes.details}>
              Already use Asurion Photos?{' '}
              <Link color="primary" onClick={handleLogin}>
                Sign in to view your gallery
              </Link>{' '}
            </div>
          </div>
          <div item md={6} className={classes.right}>
            <img src={heroImg} className={classes.img} alt="Asurion Photos" />
          </div>
        </div>
      </Grid>
    </Paper>
  );
};

export const styles = theme => ({
  root: {
    fontFamily: 'ApercuLightPro',
  },
  container: {
    margin: '150px 50px 0px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      margin: '100px 0px 0px',
    },
  },
  left: {
    height: '100%',
    width: '80%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      textAlign: 'center',
      margin: '8px 0px 0px',
    },
  },
  title: {
    fontSize: '4.25vw',
    lineHeight: '5vw',
    margin: '0px 0px 24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      lineHeight: '46px',
      margin: '0px 24px 16px',
    },
  },
  desc: {
    fontSize: '2.25vw',
    lineHeight: '2.5vw',
    margin: '0px 0px 24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '31.2px',
      margin: '0px 24px 16px',
    },
  },
  downloadBtnContainer: {
    margin: '0px 0px 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  smDownloadBtnContainer: {
    display: 'none',
    margin: '0px 0px 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  smDownloadBtn: {
    margin: '0 auto',
  },
  details: {
    fontSize: '1rem',
    lineHeight: '20.8px',
    margin: '0px 0px 24px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      margin: '0px 24px 24px',
      fontSize: '16px',
      lineHeight: '20.8px',
    },
  },
  right: {
    width: '100%',
    float: 'right',
    marginTop: '25px',
    display: 'block',
    direction: 'rtl',
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '650px',
      display: 'block',
    },
  },
});

export default withStyles(styles)(Hero);
