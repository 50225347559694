import { getClientInfo } from './clientInfoService';
import { osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { FullStory as FS } from '@fullstory/browser';

import api from './config/api';
import brand from '../core/config/brand';

import authToken from './auth';

// Pre Login Calls - No auth required.
const preLoginUrl = api.url.eventApiV2;

const requestHeaders = {
  CarrierId: brand.carrierId,
  Locale: brand.locale,
};

const version = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0';

const eventDetail = {
  eventTime: Date.now(),
  osVersion: getClientInfo().osVersion,
  appVersion: version,
  appVersionCode: '100',
  browserName: getClientInfo().browserName,
  browserVersion: getClientInfo().browserVersion,
  reportingAnalyticsVersion: 5,
  sim: 'NotExist',
  simCarrier: brand.carrierId,
};

const requestBody = {
  os: getClientInfo().osName,
  sourceType: 'Web',
  omniChannel: 'Web',
  formFactor: getClientInfo().device,
  deviceModel: getClientInfo().deviceModel || getClientInfo().device,
  vendor: getClientInfo().deviceVendor || osName,
  locale: getClientInfo().locale,
  packageName: 'MVP1',
  appName: brand.appName,
  simCompatible: 'false',
};

let uniqueId;
if (localStorage && localStorage.getItem('uniqueId')) {
  uniqueId = localStorage.getItem('uniqueId');
} else {
  uniqueId = uuidv4();
  localStorage.setItem('uniqueId', uniqueId);
}
// const FS_EVENT_PREFIX = 'Photos';

export const publishAnonEvent = async (events, user) => {
  if (process.env.NODE_ENV !== 'production')
    console.log('Reporting anonymous event', events);
  // const uniqueId = await generateDeviceID();

  let userType;

  if (user && user !== undefined) {
    userType = {
      userType: user.toLowerCase().includes('return') ? 'Return' : 'New',
    };
  }

  let headers = async () => {
    return {
      // on Asurion ID v2, use accessToken instead authToken
      Authorization: await authToken(process.env.REACT_APP_GENERATE_AUTH_TOKEN, uniqueId),
      ApiKey: api.reporting.key,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...requestHeaders,
    };
  };
  const eventTime = Date.now();
  // skip auth check since this is anonymous
  let headersData = await headers();
  const data = {
    ...requestBody,
    uniqueId: uniqueId,
    ...userType,
    events: [
      {
        ...events,
        ...eventDetail,
        eventTime,
      },
    ],
  };

  axios.post(preLoginUrl, data, { headers: headersData });

  if (process.env.REACT_APP_ENABLE_FULLSTORY_EVENTS) {
    let updatedEvent = {
      ...events,
      uniqueId,
      eventTime,
      carrierId: brand.carrierId,
      ...userType,
    };
    // delete updatedEvent.eventType; // no need to send eventType
    console.log('logging FS event', events.eventType);

    FS('trackEvent', {
      name: events.eventType,
      properties: { ...requestBody, ...eventDetail, ...updatedEvent },
    }); // To send event directly in FS
  }
};
