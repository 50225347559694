import React, { useCallback } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { publishAnonEvent } from '../../services/reportingService';
import { Button, Link } from '@soluto-private/mx-asurion-ui-react';
import { generateDeviceID } from '../../services/clientInfoService';

import asurionPhotosLogo from '../../core/assets/photos-logo.svg';

const Header = ({ classes }) => {
  const handleUserEvent = useCallback(event => {
    publishAnonEvent(event);
  }, []);
  const scrollTo = id => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };
  const handleFAQ = useCallback(() => {
    const eventDetails = {
      eventType: 'ButtonPressed',
      button: 'FAQ',
      screen: 'MarketingLandingPage',
    };
    handleUserEvent(eventDetails);
    scrollTo('faq');
  }, [handleUserEvent]);
  const handleLogin = useCallback(() => {
    const uniqueId =
      localStorage && localStorage.getItem('uniqueId')
        ? localStorage.getItem('uniqueId')
        : generateDeviceID();
    let path = `${process.env.REACT_APP_REDIRECT_URL}&u=${uniqueId}`;
    const event = {
      eventType: 'ButtonPressed',
      button: 'SignIn',
      screen: 'MarketingLandingPage',
    };
    handleUserEvent(event);
    window.location.assign(path);
  }, [handleUserEvent]);
  const handleDownload = useCallback(() => {
    const event = {
      eventType: 'ButtonPressed',
      button: 'DownloadApp',
      screen: 'MarketingLandingPage',
    };
    handleUserEvent(event);
    scrollTo('download');
  }, [handleUserEvent]);
  const handleDownloadMobile = useCallback(() => {
    publishAnonEvent({
      eventType: 'ButtonPressed',
      button: 'DownloadApp',
      screen: 'MarketingLandingPage',
    });
    window.open(process.env.REACT_APP_DOWNLOAD_MOBILE_REDIRECT_LINK, '_blank').focus();
  }, []);

  return (
    <Paper elevation={0} square className={classes.header}>
      <Paper elevation={0} square className={classes.headerLogo}>
        <div className={classes.headerLeft}>
          <img src={asurionPhotosLogo} alt="Asurion Photos" className={classes.logo} />
        </div>
        <div className={classes.headerRight}>
          <div className={classes.smBtnContainer}>
            <Link
              btnVariantProps={{
                btnSize: 'small',
                btnVariant: 'outline',
              }}
              color="primary"
              variant="button"
              className={classes.smBtn}
              onClick={handleDownloadMobile}
            >
              Download
            </Link>
          </div>
          <div className={classes.lgBtnContainer}>
            <Button
              size="medium"
              variant="flat"
              color="secondary"
              className={classes.faqBtn}
              onClick={handleFAQ}
            >
              FAQ
            </Button>
            <Button
              size="medium"
              variant="outline"
              className={classes.signInBtn}
              onClick={handleLogin}
            >
              Sign in
            </Button>
            <Button
              color="primary"
              size="medium"
              variant="default"
              className={classes.downloadBtn}
              onClick={handleDownload}
            >
              Download the app
            </Button>
          </div>
        </div>
      </Paper>
    </Paper>
  );
};
const breakpoint = 'sm';

export const styles = theme => ({
  header: {
    width: '100%',
    position: 'fixed',
    margin: '0px 0px 0px',
    height: 80,
    zIndex: 10,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(breakpoint)]: {
      height: 65,
    },
  },
  headerLogo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerLeft: {
    width: '50%',
    float: 'left',
  },
  logo: {
    marginLeft: '40px',
    width: '200px',
    height: '55px',
    [theme.breakpoints.down(breakpoint)]: {
      height: '20px',
      marginLeft: '0px',
    },
  },
  headerRight: {
    width: '50%',
    float: 'right',
  },
  smBtnContainer: {
    display: 'none',
    alignItems: 'center',
    float: 'right',
    [theme.breakpoints.down(breakpoint)]: {
      display: 'inline-block',
    },
  },
  smBtn: {
    fontFamily: 'ApercuLightPro !important',
    marginRight: '20px',
  },
  lgBtnContainer: {
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down(breakpoint)]: {
      display: 'none',
    },
  },
  faqBtn: {
    fontFamily: 'ApercuLightPro !important',
    display: 'inline-flex',
    marginRight: '16px',
  },
  signInBtn: {
    fontFamily: 'ApercuLightPro !important',
    display: 'inline-flex',
    marginRight: '30px',
  },
  downloadBtn: {
    fontFamily: 'ApercuLightPro !important',
    display: 'inline-flex',
    marginRight: '40px',
  },
});

export default withStyles(styles)(Header);
